import React, { memo } from "react";
import { isRegionUS } from "../../utils/variables";
import { mdiTruck, mdiLayers } from "@mdi/js";
import BaseNode from "./base/BaseNode";

React;

const TransferNode = memo((props) => {
  // Define left icons
  const iconsLeft = {
    color: "#d0d132",
    props: [
      {
        path: mdiTruck,
        title: "Transfer",
      },
    ],
  };
  // US Only for now
  const inventory = !isRegionUS
    ? null
    : {
        // green if inventory is present, gray if not present
        color: props.data.detail.inventory?.length ? "green" : "lightgray",
        path: mdiLayers,
        title: "Inventory",
      };

  return (
    <BaseNode
      {...props}
      handles={["top", "bottom", "right"]}
      iconsLeft={iconsLeft}
      inventory={inventory}
    />
  );
});

export { TransferNode };
