import React, { memo } from "react";
import { isRegionUS } from "../../utils/variables.js";
import BaseNode from "./base/BaseNode.jsx";
import { mdiInbox, mdiTrayArrowUp, mdiTrayArrowDown, mdiLayers } from "@mdi/js";
import { usePartPreSchedule } from "../../store/partPreSchedule.pinia.js";

React;

const BufferNode = memo((props) => {
  let linkedFlowIds = props?.data?.related_buffers;
  const partPreScheduleStore = usePartPreSchedule();

  const partPreSchedule = partPreScheduleStore.getPartPrescheduleFromFlowNode(
    props?.data?.detail,
    true
  );

  const outFlows = linkedFlowIds.filter((obj) => obj.type === "SourceBuffer");
  const noDupes = [...new Set(outFlows)];
  const hasRelatedBuffers = noDupes.length > 0;
  const outString = hasRelatedBuffers
    ? "Out (" + noDupes.length + " Flows)"
    : "No other flow pulls from this buffer";
  let iconsLeft;
  let iconColor = "#513f77";
  let iconProps = [
    {
      path: mdiInbox,
      title: "Buffer",
      disabled: true,
    },
    {
      path: mdiTrayArrowUp,
      title: outString,
      disabled: !hasRelatedBuffers,
      type: "out",
    },
    {
      path: mdiTrayArrowDown,
      title: "In",
      type: "in",
    },
  ];

  const bufferType = props.type.toLowerCase();
  if (bufferType === "sourcebuffer") {
    iconColor = "#625c6f";
  } else if (bufferType === "destinationbuffer") {
    iconProps = [
      {
        path: mdiInbox,
        title: "Buffer",
        disabled: true,
      },
      {
        path: mdiTrayArrowUp,
        title: outString,
        disabled: !hasRelatedBuffers,
        type: "out",
      },
    ];
  }

  iconsLeft = {
    color: iconColor,
    props: iconProps,
  };
  const inventory =
    // US Only for now
    !isRegionUS || props.data?.detail?.metadata?.hide_inventory
      ? null
      : {
          // green if inventory is present, red if not present
          color:
            props.data.detail.inventory?.length ||
            (props.data.is_non_mrp_flow &&
              partPreSchedule.value?.on_hand_qty > 0)
              ? "green"
              : "red",
          path: mdiLayers,
          title: "Inventory",
        };

  return (
    <BaseNode
      {...props}
      handles={true}
      iconsLeft={iconsLeft}
      linkedFlowIds={linkedFlowIds}
      inventory={inventory}
      slot={
        <div>
          <div style={{ marginRight: "24px" }}>
            Part: {props?.data?.detail?.part?.part_number}
          </div>
          {props?.type === "SourceBuffer" && props?.data?.detail?.bom ? (
            <div
              style={{
                textAlign: "right",
                marginRight: "8px",
                marginTop: "-18px",
              }}
            >
              <div>
                QTY:{" "}
                {props?.data?.detail?.bom?.quantity
                  ? props?.data?.detail?.bom?.quantity
                  : 0}{" "}
                {props?.data?.detail?.part?.uom?.description}
              </div>
            </div>
          ) : null}
          <div>Desc: {props?.data?.detail?.part?.description}</div>
          <div>Loc: {props?.data?.buffer?.description}</div>
        </div>
      }
    />
  );
});

export { BufferNode };
